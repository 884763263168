import { OrderType } from '../../components/DataTable/types';

export interface RoleFilterParams {
  page: number;
  pageCount: number;
  column?: string;
  order?: OrderType;
}

export interface RoleObject {
  'Raporlar': string;
  'Dashboard': string;
  'Çekimler': string;
  'API Gönder': string;
  'Merchantlar': string;
  'Yatırımlar': string;
  'Fraud Listesi': string;
  'Yetki Rolleri': string;
  'Kullanıcılar': string;
  'Papara Kasalar': string;
  'Papara Kasa Sil': string;
  'MFT Kasalar': string;
  'MFT Kasa Sil': string;
  'Papara Mailleri': string;
  'GA kodu yenileme': string;
  'Çekim Bilgileri': string;
  'Güncel Paparalar': string;
  'Program Ayarları': string;
  'Merchant Oluşturma': string;
  'Merchant Düzenleme': string;
  'Yatırım Bilgileri': string;
  'Çekim API Logları': string;
  'Şifre Değiştirme': string;
  'Papara Kasa Düzenle': string;
  'Papara Kasa Ekstresi': string;
  'Papara Kasa Oluştur': string;
  'Papara Kasa Transfer': string;
  'MFT Kasa Düzenle': string;
  'MFT Kasa Ekstresi': string;
  'MFT Kasa Oluştur': string;
  'MFT Kasa Transfer': string;
  'Yetki Rol Düzenleme': string;
  'Çekim Detay Logları': string;
  'Banka Havalesi Kasalar': string;
  'Kullanıcı düzenleme': string;
  'Yatırım API Logları': string;
  'Çekim Sonuçlandırma': string;
  'Banka Havalesi Kasa Sil': string;
  'Dashboard istatistikler': string;
  'Dashboard toplam Havale': string;
  'Dashboard toplam Kripto': string;
  'Dashboard toplam Papara': string;
  'Dashboard toplam MFT': string;
  'Dashboard toplam bakiye': string;
  'Manüel Çekim Oluştur': string;
  'Yatırım Detay Logları': string;
  'Banka Havalesi Bankaları': string;
  'Papara Kasa Bakiye Ekleme': string;
  'MFT Kasa Bakiye Ekleme': string;
  'Manüel Yatırım Oluştur': string;
  'Papara Kasa Bakiye Azaltma': string;
  'MFT Kasa Bakiye Azaltma': string;
  'Havale İşlemlerini Görme': string;
  'Papara İşlemlerini Görme': string;
  'Kripto İşlemlerini Görme': string;
  'MFT İşlemlerini Görme': string;
  'Tarayıcı mid bildirimleri': string;
  'Yeni Kullanıcı Oluşturma': string;
  'Banka Havalesi Bakiye Ekleme': string;
  'Banka Havalesi Kasa Düzenle': string;
  'Banka Havalesi Kasa Ekstresi': string;
  'Banka Havalesi Kasa Oluştur': string;
  'Banka Havalesi Kasa Transfer': string;
  'Banka Havalesi Bakiye Azaltma': string;
  'Kripto Kasalar': string;
  'Kripto Kasa Sil': string;
  'Kripto Kasa Bakiye Ekleme': string;
  'Kripto Kasa Düzenle': string;
  'Kripto Kasa Ekstresi': string;
  'Kripto Kasa Oluştur': string;
  'Kripto Kasa Transfer': string;
  'Kripto Kasa Bakiye Azaltma': string;
  'Papara Mailleri Gereksizleri Sil': string;
  'OneSignal tarayıcı bildirimleri': string;
  'Üstlenmiş Çekim Görme/İşlem': string;
  'Üstlenmiş Yatırım Görme/İşlem': string;
  'Dashborad Backuser Performans Analizi': string;
  'Yatırım Manüel Hesabına Aktardım Butonu': string;
  'Yeni Havale Yatırımları / Sonuçlandırma': string;
  'Yeni Papara Yatırımları / Sonuçlandırma': string;
  'Yeni MFT Yatırımları / Sonuçlandırma': string;
  'Yeni Kripto Yatırımları / Sonuçlandırma': string;
  'Yatırım detayında Manüel yatırım oluşturma butonu': string;
  'Manuel İşlemler': string;
  'Havale Çekim Görme': string;
  'Papara Çekim Görme': string;
  'MFT Çekim Görme': string;
  'Kripto Çekim Görme': string;
  'Banka Set Listesi': string;
  'Kredi Kart Kasalar': string;
  'Kredi Kart Kasa Sil': string;
  'Kredi Kart Kasa Bakiye Ekleme': string;
  'Kredi Kart Kasa Düzenle': string;
  'Kredi Kart Kasa Ekstresi': string;
  'Kredi Kart Kasa Oluştur': string;
  'Kredi Kart Kasa Transfer': string;
  'Kredi Kart Kasa Bakiye Azaltma': string;
  'Dashboard toplam Kredi Kart': string;
  'Kredi Kart İşlemlerini Görme': string;
  'Yeni Kredi Kart Yatırımları / Sonuçlandırma': string;
  'Kredi Kart Çekim Görme': string;
  'PayFix Kasalar': string;
  'PayFix Kasa Sil': string;
  'PayFix Kasa Bakiye Ekleme': string;
  'PayFix Kasa Düzenle': string;
  'PayFix Kasa Ekstresi': string;
  'PayFix Kasa Oluştur': string;
  'PayFix Kasa Transfer': string;
  'PayFix Kasa Bakiye Azaltma': string;
  'Payco Kasalar': string;
  'Payco Kasa Sil': string;
  'Payco Kasa Bakiye Ekleme': string;
  'Payco Kasa Düzenle': string;
  'Payco Kasa Ekstresi': string;
  'Payco Kasa Oluştur': string;
  'Payco Kasa Transfer': string;
  'Payco Kasa Bakiye Azaltma': string;
  'Dashboard toplam PayFix': string;
  'PayFix İşlemlerini Görme': string;
  'Yeni PayFix Yatırımları / Sonuçlandırma': string;
  'Payco İşlemlerini Görme': string;
  'Yeni Payco Yatırımları / Sonuçlandırma': string;
  'PayFix Çekim Görme': string;
  'Dashboard toplam Payco': string;
}

export interface RoleInfoFields {
  isim: string;
  role_yonetim_listesi: RoleObject;
}

export const RoleInfoInitial = {
  isim: '',
  role_yonetim_listesi: {
    'Raporlar': '1',
    'Dashboard': '1',
    'Çekimler': '1',
    'API Gönder': '1',
    'Merchantlar': '1',
    'Yatırımlar': '1',
    'Fraud Listesi': '1',
    'Yetki Rolleri': '1',
    'Kullanıcılar': '1',
    'Papara Kasalar': '1',
    'Papara Kasa Sil': '1',
    'MFT Kasalar': '1',
    'MFT Kasa Sil': '1',
    'Papara Mailleri': '1',
    'GA kodu yenileme': '1',
    'Çekim Bilgileri': '1',
    'Güncel Paparalar': '1',
    'Program Ayarları': '1',
    'Merchant Oluşturma': '1',
    'Merchant Düzenleme': '1',
    'Yatırım Bilgileri': '1',
    'Çekim API Logları': '1',
    'Şifre Değiştirme': '1',
    'Papara Kasa Düzenle': '1',
    'Papara Kasa Ekstresi': '1',
    'Papara Kasa Oluştur': '1',
    'Papara Kasa Transfer': '1',
    'MFT Kasa Düzenle': '1',
    'MFT Kasa Ekstresi': '1',
    'MFT Kasa Oluştur': '1',
    'MFT Kasa Transfer': '1',
    'Yetki Rol Düzenleme': '1',
    'Çekim Detay Logları': '1',
    'Banka Havalesi Kasalar': '1',
    'Kullanıcı düzenleme': '1',
    'Yatırım API Logları': '1',
    'Çekim Sonuçlandırma': '1',
    'Banka Havalesi Kasa Sil': '1',
    'Dashboard istatistikler': '1',
    'Dashboard toplam Havale': '1',
    'Dashboard toplam Papara': '1',
    'Dashboard toplam bakiye': '1',
    'Dashboard toplam Kripto': '1',
    'Dashboard toplam MFT': '1',
    'Manüel Çekim Oluştur': '1',
    'Yatırım Detay Logları': '1',
    'Banka Havalesi Bankaları': '1',
    'Papara Kasa Bakiye Ekleme': '1',
    'MFT Kasa Bakiye Ekleme': '1',
    'Manüel Yatırım Oluştur': '1',
    'Papara Kasa Bakiye Azaltma': '1',
    'MFT Kasa Bakiye Azaltma': '1',
    'Havale İşlemlerini Görme': '1',
    'Kripto İşlemlerini Görme': '1',
    'Papara İşlemlerini Görme': '1',
    'MFT İşlemlerini Görme': '1',
    'Tarayıcı mid bildirimleri': '1',
    'Yeni Kullanıcı Oluşturma': '1',
    'Banka Havalesi Bakiye Ekleme': '1',
    'Banka Havalesi Kasa Düzenle': '1',
    'Banka Havalesi Kasa Ekstresi': '1',
    'Banka Havalesi Kasa Oluştur': '1',
    'Banka Havalesi Kasa Transfer': '1',
    'Banka Havalesi Bakiye Azaltma': '1',
    'Kripto Kasalar': '1',
    'Kripto Kasa Sil': '1',
    'Kripto Kasa Bakiye Ekleme': '1',
    'Kripto Kasa Düzenle': '1',
    'Kripto Kasa Ekstresi': '1',
    'Kripto Kasa Oluştur': '1',
    'Kripto Kasa Transfer': '1',
    'Kripto Kasa Bakiye Azaltma': '1',
    'Papara Mailleri Gereksizleri Sil': '1',
    'OneSignal tarayıcı bildirimleri': '1',
    'Üstlenmiş Çekim Görme/İşlem': '1',
    'Üstlenmiş Yatırım Görme/İşlem': '1',
    'Dashborad Backuser Performans Analizi': '1',
    'Yatırım Manüel Hesabına Aktardım Butonu': '1',
    'Yeni Havale Yatırımları / Sonuçlandırma': '1',
    'Yeni Papara Yatırımları / Sonuçlandırma': '1',
    'Yeni MFT Yatırımları / Sonuçlandırma': '1',
    'Yeni Kripto Yatırımları / Sonuçlandırma': '1',
    'Yatırım detayında Manüel yatırım oluşturma butonu': '1',
    'Manuel İşlemler': '1',
    'Havale Çekim Görme': '1',
    'Papara Çekim Görme': '1',
    'MFT Çekim Görme': '1',
    'Kripto Çekim Görme': '1',
    'Banka Set Listesi': '1',

    'Dashboard toplam Kredi Kart': '1',
    'Kredi Kart İşlemlerini Görme': '1',
    'Kredi Kart Kasalar': '1',
    'Kredi Kart Kasa Sil': '1',
    'Kredi Kart Kasa Bakiye Ekleme': '1',
    'Kredi Kart Kasa Düzenle': '1',
    'Kredi Kart Kasa Ekstresi': '1',
    'Kredi Kart Kasa Oluştur': '1',
    'Kredi Kart Kasa Transfer': '1',
    'Kredi Kart Kasa Bakiye Azaltma': '1',
    'Kredi Kart Çekim Görme': '1',
    'Yeni Kredi Kart Yatırımları / Sonuçlandırma': '1',

    'Dashboard toplam PayFix': '1',
    'PayFix İşlemlerini Görme': '1',
    'PayFix Kasalar': '1',
    'PayFix Kasa Sil': '1',
    'PayFix Kasa Bakiye Ekleme': '1',
    'PayFix Kasa Düzenle': '1',
    'PayFix Kasa Ekstresi': '1',
    'PayFix Kasa Oluştur': '1',
    'PayFix Kasa Transfer': '1',
    'PayFix Kasa Bakiye Azaltma': '1',
    'Yeni PayFix Yatırımları / Sonuçlandırma': '1',
    'PayFix Çekim Görme': '1',

    'Dashboard toplam Payco': '1',
    'Payco İşlemlerini Görme': '1',
    'Payco Kasalar': '1',
    'Payco Kasa Sil': '1',
    'Payco Kasa Bakiye Ekleme': '1',
    'Payco Kasa Düzenle': '1',
    'Payco Kasa Ekstresi': '1',
    'Payco Kasa Oluştur': '1',
    'Payco Kasa Transfer': '1',
    'Payco Kasa Bakiye Azaltma': '1',
    'Yeni Payco Yatırımları / Sonuçlandırma': '1',
    'Payco Çekim Görme': '1'
  },
};

export interface RoleInfoParams {
  id: string | undefined;
}
