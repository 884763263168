import { loginThunk } from './loginThunk';
import { getMerchantListThunk } from './Merchant/getMerchantListThunk';
import { getWholeMerchantListThunk } from './Merchant/getWholeMerchantListThunk';
import { getWholeUserListThunk } from './User/getWholeUserListThunk';
import { getMerchantsThunk } from './Merchant/getMerchantsThunk';
import { getUsersThunk } from './User/getUsersThunk';
import { getUserInfoThunk } from './User/getUserInfoThunk';
import { getNewTransfersThunk } from './NewTransfer/getNewTransfersThunk';
import { getNewTransferInfoThunk } from './NewTransfer/getNewTransferInfoThunk';
import { getNewPaparaTransfersThunk } from './NewPaparaTransfer/getNewPaparaTransfersThunk';
import { getNewPaparaTransferInfoThunk } from './NewPaparaTransfer/getNewPaparaTransferInfoThunk';
import { getNewMFTTransfersThunk } from './NewMFTTransfer/getNewMFTTransfersThunk';
import { getNewMFTTransferInfoThunk } from './NewMFTTransfer/getNewMFTTransferInfoThunk';
import { getNewCryptoTransfersThunk } from './NewCryptoTransfer/getNewCryptoTransfersThunk';
import { getNewCryptoTransferInfoThunk } from './NewCryptoTransfer/getNewCryptoTransferInfoThunk';
import { getNewWithdrawBankTransfersThunk } from './NewWithdrawBankTransfer/getNewWithdrawBankTransfersThunk';
import { getDepositsThunk } from './Deposit/getDepositsThunk';
import { getDepositInfoThunk } from './Deposit/getDepositInfoThunk';
import { getWithdrawsBankThunk } from './WithdrawBank/getWithdrawsBankThunk';
import { getWithdrawBankInfoThunk } from './WithdrawBank/getWithdrawBankInfoThunk';
import { getWithdrawsPaparaThunk } from './WithdrawPapara/getWithdrawsPaparaThunk';
import { getWithdrawPaparaInfoThunk } from './WithdrawPapara/getWithdrawPaparaInfoThunk';
import { getWithdrawsMFTThunk } from './WithdrawMFT/getWithdrawsMFTThunk';
import { getWithdrawMFTInfoThunk } from './WithdrawMFT/getWithdrawMFTInfoThunk';
import { getWithdrawsCryptoThunk } from './WithdrawCrypto/getWithdrawsCryptoThunk';
import { getWithdrawCryptoInfoThunk } from './WithdrawCrypto/getWithdrawCryptoInfoThunk';
import { getPaparaMailsThunk } from './PaparaMail/getPaparaMailsThunk';
import { getReportsThunk } from './Report/getReportsThunk';
import { getPaparaRegistersThunk } from './PaparaRegister/getPaparaRegistersThunk';
import { getPaparaRegisterListThunk } from './PaparaRegister/getPaparaRegisterListThunk';
import { getMFTRegistersThunk } from './MFTRegister/getMFTRegistersThunk';
import { getMFTRegisterListThunk } from './MFTRegister/getMFTRegisterListThunk';
import { getBankRegisterListThunk } from './BankRegister/getBankRegisterListThunk';
import { getPaparaRegisterDetailsThunk } from './PaparaRegister/getPaparaRegisterDetailsThunk';
import { getPaparaRegisterInfoThunk } from './PaparaRegister/getPaparaRegisterInfoThunk';

import { getMFTRegisterDetailsThunk } from './MFTRegister/getMFTRegisterDetailsThunk';
import { getMFTRegisterInfoThunk } from './MFTRegister/getMFTRegisterInfoThunk';
import { getBankRegistersThunk } from './BankRegister/getBankRegistersThunk';
import { getCryptoRegistersThunk } from './CryptoRegisters/getCryptoRegistersThunk';
import { getCryptoRegisterListThunk } from './CryptoRegisters/getCryptoRegisterListThunk';
import { getCryptoRegisterDetailsThunk } from './CryptoRegisters/getCryptoRegisterDetailsThunk';
import { getCryptoRegisterInfoThunk } from './CryptoRegisters/getCryptoRegisterInfoThunk';
import { getNewCryptoTransferRegistersThunk } from './NewCryptoTransfer/getNewCryptoTransferRegistersThunk';
import { getNewTransferBankRegistersThunk } from './NewTransfer/getNewTransferBankRegistersThunk';
import { getNewPaparaTransferRegistersThunk } from './NewPaparaTransfer/getNewPaparaTransferRegistersThunk';
import { getNewMFTTransferRegistersThunk } from './NewMFTTransfer/getNewMFTTransferRegistersThunk';
import { getBankListThunk } from './Bank/getBankListThunk';
import { getBanksThunk } from './Bank/getBanksThunk';
import { getBankRegisterDetailsThunk } from './BankRegister/getBankRegisterDetailsThunk';
import { getBankRegisterInfoThunk } from './BankRegister/getBankRegisterInfoThunk';
import { getRoleListThunk } from './Role/getRoleListThunk';
import { getRolesThunk } from './Role/getRolesThunk';
import { getRoleInfoThunk } from './Role/getRoleInfoThunk';
import { getMerchantInfoThunk } from './Merchant/getMerchantInfoThunk';
import { getFraudsThunk } from './Fraud/getFraudsThunk';
import { getBankSetThunk } from './BanksSet/getBankSetThunk';
import { getCurrentPaparaListThunk } from './CurrentPaparaList/getCurrentPaparaListThunk';
import { getCurrentUserThunk } from './User/getCurrentUserThunk';
import { getDashboardInfoThunk } from './Dashboard/getDashboardInfoThunk';
import { getNewWithdrawPaparaTransfersThunk } from './NewWithdrawPaparaTransfer/getNewWithdrawPaparaTransfersThunk';
import { getNewWithdrawMFTTransfersThunk } from './NewWithdrawMFTTransfer/getNewWithdrawMFTTransfersThunk';
import { getNewWithdrawCryptoTransfersThunk } from './NewWithdrawCryptoTransfer/getNewWithdrawCryptoTransfersThunk';
import { getNewCreditCardTransfersThunk } from './NewCreditCardTransfer/getNewCreditCardTransfersThunk';
import { getNewCreditCardTransferInfoThunk } from './NewCreditCardTransfer/getNewCreditCardTransferInfoThunk';
import { getWithdrawsCreditCardThunk } from './WithdrawCreditCard/getWithdrawsCreditCardThunk';
import { getWithdrawCreditCardInfoThunk } from './WithdrawCreditCard/getWithdrawCreditCardInfoThunk';
import { getCreditCardRegistersThunk } from './CreditCardRegisters/getCreditCardRegistersThunk';
import { getCreditCardRegisterListThunk } from './CreditCardRegisters/getCreditCardRegisterListThunk';
import { getCreditCardRegisterDetailsThunk } from './CreditCardRegisters/getCreditCardRegisterDetailsThunk';
import { getCreditCardRegisterInfoThunk } from './CreditCardRegisters/getCreditCardRegisterInfoThunk';
import { getNewCreditCardTransferRegistersThunk } from './NewCreditCardTransfer/getNewCreditCardTransferRegistersThunk';
import { getNewWithdrawCreditCardTransfersThunk } from './NewWithdrawCreditCardTransfer/getNewWithdrawCreditCardTransfersThunk';


import { getPayFixRegistersThunk } from './PayFix/getPayFixRegistersThunk';
import { getPayFixRegisterListThunk } from './PayFix/getPayFixRegisterListThunk';
import { getPayFixRegisterDetailsThunk } from './PayFix/getPayFixRegisterDetailsThunk';
import { getPayFixRegisterInfoThunk } from './PayFix/getPayFixRegisterInfoThunk';
import { getNewPayfixTransfersThunk } from './NewPayfixTransfer/getNewPayfixTransfersThunk';
import { getNewPayfixTransferInfoThunk } from './NewPayfixTransfer/getNewPayfixTransferInfoThunk';
import { getWithdrawsPayfixThunk } from './WithdrawPayfix/getWithdrawsPayfixThunk';
import { getWithdrawPayfixInfoThunk } from './WithdrawPayfix/getWithdrawPayfixInfoThunk';
import { getNewWithdrawPayfixTransfersThunk } from './NewWithdrawPayfixTransfer/getNewWithdrawPayfixTransfersThunk';
import { getNewPayfixTransferRegistersThunk } from './NewPayfixTransfer/getNewPayfixTransferRegistersThunk';


import { getPaycoRegistersThunk } from './Payco/getPaycoRegistersThunk';
import { getPaycoRegisterListThunk } from './Payco/getPaycoRegisterListThunk';
import { getPaycoRegisterDetailsThunk } from './Payco/getPaycoRegisterDetailsThunk';
import { getPaycoRegisterInfoThunk } from './Payco/getPaycoRegisterInfoThunk';
import { getNewPaycoTransfersThunk } from './NewPaycoTransfer/getNewPaycoTransfersThunk';
import { getNewPaycoTransferInfoThunk } from './NewPaycoTransfer/getNewPaycoTransferInfoThunk';
import { getWithdrawsPaycoThunk } from './WithdrawPayco/getWithdrawsPaycoThunk';
import { getWithdrawPaycoInfoThunk } from './WithdrawPayco/getWithdrawPaycoInfoThunk';
import { getNewWithdrawPaycoTransfersThunk } from './NewWithdrawPaycoTransfer/getNewWithdrawPaycoTransfersThunk';
import { getNewPaycoTransferRegistersThunk } from './NewPaycoTransfer/getNewPaycoTransferRegistersThunk';

const thunks = {
  loginThunk,
  getMerchantListThunk,
  getWholeMerchantListThunk,
  getWholeUserListThunk,
  getMerchantsThunk,
  getUsersThunk,
  getUserInfoThunk,
  getNewTransfersThunk,
  getNewTransferInfoThunk,
  getNewPaparaTransfersThunk,
  getNewPaparaTransferInfoThunk,
  getNewPayfixTransfersThunk,
  getNewPaycoTransfersThunk,
  getNewPayfixTransferInfoThunk,
  getNewPaycoTransferInfoThunk,
  getNewMFTTransfersThunk,
  getNewMFTTransferInfoThunk,
  getNewCryptoTransfersThunk,
  getNewCryptoTransferInfoThunk,
  getNewCreditCardTransfersThunk,
  getNewCreditCardTransferInfoThunk,
  getNewWithdrawBankTransfersThunk,
  getNewWithdrawPaparaTransfersThunk,
  getNewWithdrawPayfixTransfersThunk,
  getNewWithdrawPaycoTransfersThunk,
  getNewWithdrawMFTTransfersThunk,
  getNewWithdrawCryptoTransfersThunk,
  getNewWithdrawCreditCardTransfersThunk,
  getDepositsThunk,
  getDepositInfoThunk,
  getWithdrawsBankThunk,
  getWithdrawBankInfoThunk,
  getWithdrawsPaparaThunk,
  getWithdrawPaparaInfoThunk,
  getWithdrawsPayfixThunk,
  getWithdrawsPaycoThunk,
  getWithdrawPayfixInfoThunk,
  getWithdrawPaycoInfoThunk,
  getWithdrawsMFTThunk,
  getWithdrawMFTInfoThunk,
  getWithdrawsCryptoThunk,
  getWithdrawCryptoInfoThunk,
  getWithdrawsCreditCardThunk,
  getWithdrawCreditCardInfoThunk,
  getPaparaMailsThunk,
  getReportsThunk,
  getPaparaRegistersThunk,
  getPaparaRegisterListThunk,
  getPaparaRegisterDetailsThunk,
  getPaparaRegisterInfoThunk,
  getPayFixRegistersThunk,
  getPaycoRegistersThunk,
  getPayFixRegisterListThunk,
  getPaycoRegisterListThunk,
  getPayFixRegisterDetailsThunk,
  getPaycoRegisterDetailsThunk,
  getPayFixRegisterInfoThunk,
  getPaycoRegisterInfoThunk,
  getMFTRegistersThunk,
  getMFTRegisterListThunk,
  getMFTRegisterDetailsThunk,
  getMFTRegisterInfoThunk,
  getCryptoRegistersThunk,
  getCryptoRegisterListThunk,
  getCryptoRegisterDetailsThunk,
  getCryptoRegisterInfoThunk,
  getCreditCardRegistersThunk,
  getCreditCardRegisterListThunk,
  getCreditCardRegisterDetailsThunk,
  getCreditCardRegisterInfoThunk,
  getBankRegistersThunk,
  getNewTransferBankRegistersThunk,
  getNewPaparaTransferRegistersThunk,
  getNewPayfixTransferRegistersThunk,
  getNewPaycoTransferRegistersThunk,
  getNewMFTTransferRegistersThunk,
  getNewCryptoTransferRegistersThunk,
  getNewCreditCardTransferRegistersThunk,
  getBankRegisterListThunk,
  getBankListThunk,
  getBanksThunk,
  getBankRegisterDetailsThunk,
  getBankRegisterInfoThunk,
  getRoleListThunk,
  getRolesThunk,
  getRoleInfoThunk,
  getMerchantInfoThunk,
  getFraudsThunk,
  getBankSetThunk,
  getCurrentPaparaListThunk,
  getCurrentUserThunk,
  getDashboardInfoThunk,
};

export default thunks;
