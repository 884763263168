export const routes = {
  dashboard: 'dashboard',
  yenihavale: 'yenihavale',
  yenipapara: 'yenipapara',
  yenimft: 'yenimft',
  yenikripto: 'yenikripto',
  raporlar: 'raporlar',
  yatirimlar: 'yatirimlar',
  havaleCekimler: 'cekimler/havale',
  paparaCekimler: 'cekimler/papara',
  mftCekimler: 'cekimler/mft',
  kriptoCekimler: 'cekimler/kripto',
  guncelPaparalar: 'guncel-paparalar',
  paparaKasalar: 'papara-kasalar',
  paparaKasaOlustur: 'papara-kasa-olustur',
  paparaKasaTransfer: 'papara-kasa-transfer',
  paparaKasaDetay: 'papara-kasa-detay/:id',
  paparaKasaDuzenle: 'papara-kasa-duzenle/:id',
  mftKasalar: 'mft-kasalar',
  mftKasaOlustur: 'mft-kasa-olustur',
  mftKasaTransfer: 'mft-kasa-transfer',
  mftKasaDetay: 'mft-kasa-detay/:id',
  mftKasaDuzenle: 'mft-kasa-duzenle/:id',
  kriptoKasalar: 'kripto-kasalar',
  kriptoKasaOlustur: 'kripto-kasa-olustur',
  kriptoKasaTransfer: 'kripto-kasa-transfer',
  kriptoKasaDetay: 'kripto-kasa-detay/:id',
  kriptoKasaDuzenle: 'kripto-kasa-duzenle/:id',
  bankalar: 'bankalar',
  bankaKasalar: 'banka-kasalar',
  bankaKasaOlustur: 'banka-kasa-olustur',
  bankaKasaTransfer: 'banka-kasa-transfer',
  bankaKasaDetay: 'banka-kasa-detay/:id',
  bankaKasaDuzenle: 'banka-kasa-duzenle/:id',
  paparaMailler: 'papara-mailler',
  manuelYatirimOlustur: 'manuel-yatirim-olustur',
  manuelCekimOlustur: 'manuel-cekim-olustur',
  merchantlar: 'merchantlar',
  merchantOlustur: 'merchant-olustur',
  merchantDuzenle: 'merchant-duzenle/:id',
  kullanicilar: 'kullanicilar',
  kullaniciOlustur: 'kullanici-olustur',
  kullaniciDuzenle: 'kullanici-duzenle/:id',
  yetkiler: 'yetkiler',
  yetkiDuzenle: 'yetki-duzenle/:id',
  fraudListesi: 'fraud-listesi',
  bankaSetListesi: 'banka-set-listesi',
  sifreDegistir: 'sifre-degistir',
  validateOtp: 'validateOtp',
  yenikredikart: 'yenikredikart',
  kredikartCekimler: 'cekimler/kredikart',
  kredikartKasalar: 'kredikart-kasalar',
  kredikartKasaOlustur: 'kredikart-kasa-olustur',
  kredikartKasaTransfer: 'kredikart-kasa-transfer',
  kredikartKasaDetay: 'kredikart-kasa-detay/:id',
  kredikartKasaDuzenle: 'kredikart-kasa-duzenle/:id',
  yenipayfix: 'yenipayfix',
  payfixCekimler: 'cekimler/payfix',
  payfixKasalar: 'payfix-kasalar',
  payfixKasaOlustur: 'payfix-kasa-olustur',
  payfixKasaTransfer: 'payfix-kasa-transfer',
  payfixKasaDetay: 'payfix-kasa-detay/:id',
  payfixKasaDuzenle: 'payfix-kasa-duzenle/:id',

  yenipayco: 'yenipayco',
  paycoCekimler: 'cekimler/payco',
  paycoKasalar: 'payco-kasalar',
  paycoKasaOlustur: 'payco-kasa-olustur',
  paycoKasaTransfer: 'payco-kasa-transfer',
  paycoKasaDetay: 'payco-kasa-detay/:id',
  paycoKasaDuzenle: 'payco-kasa-duzenle/:id',
};
