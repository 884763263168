import React, { useState } from 'react';
import Box from '../../components/Box/Box';
import Row from '../../components/utils/Row/Row';
import {
  TransferItemEntry,
  TransferItemStatus,
  TransferItemStatusEnum,
} from './types';
import Column from '../../components/utils/Column/Column';
import Modal from '../../components/Modal/Modal';
import NewPayfixTransferDetail from './NewPayfixTransferDetail';
import { useActions } from '../../redux/hooks';
import Button, { Color, Icon } from '../../components/Button/Button';

interface TransferItemProps {
  transferItem: TransferItemEntry;
}

const PayfixTransferItem: React.FC<TransferItemProps> = ({
  transferItem: {
    id,
    merchant,
    durum,
    musteri,
    banka,
    verilen_banka,
    tam_adi,
    miktar,
    sure,
    backuser,
  },
}) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const { getNewPayfixTransferInfoThunk, setLoading, showMessage } = useActions();

  const closeModal = () => {
    setIsDetailsOpen(false);
  };

  const copyPaycoRegisterInfo = async (merchant: string, tam_adi: string, miktar: string, verilen_hesap: string, method: string) => {
    await window.navigator.clipboard.writeText(
      `Merchant: ${merchant}
      Tam Adı: ${tam_adi}
      Miktar: ${miktar}
      Verilen Hesap: ${verilen_hesap}
      Method: ${method}`
    );
    showMessage({
      message: 'Bilgiler panoya kopyalandı',
      icon: 'information',
    });
  };


  return (
    <Row width="100%">
      <Modal
        onChange={(isOpen) => {
          setIsDetailsOpen(isOpen);
        }}
        open={isDetailsOpen}
        width="80vw"
      >
        <NewPayfixTransferDetail id={id} closeModal={closeModal} />
      </Modal>
      <Box
        onClick={async () => {
          setLoading(true);
          await getNewPayfixTransferInfoThunk({ id });
          setLoading(false);
          setIsDetailsOpen(true);
        }}
      >
        <Row
          width="100%"
          className={`transfer-item-row ${TransferItemStatusEnum[durum]}`}
        >
          <Column width="33%" className={`transfer-item`}>
            <Row className="transfer-item-field">
              <div>
                Merchant: <strong>{merchant}</strong>
              </div>
            </Row>
            <Row className="transfer-item-field">
              <div>
                Müşteri: <strong>{musteri}</strong>
              </div>
            </Row>
            <Row className="transfer-item-field">
              <div>
                Tam adı: <strong>{tam_adi}</strong>
              </div>
            </Row>
          </Column>
          <Column width="33%" className={`transfer-item`}>
            <Row className="transfer-item-field">
              <div>
                İşlem <strong>{sure}</strong> dk önce başladı...
              </div>
            </Row>
            <Row className="transfer-item-field">
              <div>
                Banka: <strong>{banka}</strong>
              </div>
            </Row>
            <Row className="transfer-item-field">
              <div>
                Miktar: <strong>{miktar} TL</strong>
              </div>
            </Row>
          </Column>
          <Column width="33%" className={`transfer-item`}>
            <Row className="transfer-item-field">
              <div>
                Durum: <strong>{TransferItemStatus[durum]}</strong>
              </div>
            </Row>
            {verilen_banka && (
              <Row className="transfer-item-field">
                <div>
                  Verilen banka: <strong>{verilen_banka}</strong>
                </div>
              </Row>
            )}
            {backuser && (
              <Row className="transfer-item-field">
                <div>
                  Backuser: <strong>{backuser}</strong>
                </div>
              </Row>
            )}
          </Column>
        </Row>
      </Box>
      <Box className={`transfer-item-row ${TransferItemStatusEnum[durum]}`} style={{ width: '10%'}}
        onClick={async () => {
          setLoading(true);
          await copyPaycoRegisterInfo(merchant, tam_adi, miktar, verilen_banka, 'Payco');
          setLoading(false);
        }}
      >
        <Row
          width="100%"
        >
            <Column width="35px" height="35px" margin="0" padding="0">
            <Button
              className={`transfer-item-row ${TransferItemStatusEnum[durum]}`}
              onClick={async () => {
                setLoading(true);
                await copyPaycoRegisterInfo(merchant, tam_adi, miktar, verilen_banka, 'Payco');
                setLoading(false);
              }}
              icon={Icon.copy}
              color={Color.purple}
            />
            </Column>
        </Row>
      </Box>
    </Row>
  );
};

export default PayfixTransferItem;
